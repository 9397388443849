import { format } from '../../lib/date';
import getAPIRequest from '../../utils/getAPIRequest';

export const dateParam = format('yyyy-MM-dd');
export const numberParam = (value) => Number(value) || 0;

class BFF {
  static getPropertiesContent(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyIds) =>
      instance
        .get('/properties/content', {
          params: { propertyIds: propertyIds.join() },
        })
        .then((res) => res.data);
  }

  static getPropertyContent(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyId) =>
      instance.get(`/properties/${propertyId}/content`).then((res) => res.data);
  }

  static getPackagesCheapestOffer(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      departureDate,
      returnDate,
      adults,
      children,
      infants,
      originCode,
      destinationCodes,
      travelClass,
      ...propertyIdsByDestination
    }) => {
      const params = {
        departureDate,
        returnDate,
        adults,
        children,
        infants,
        originCode,
        destinationCodes: destinationCodes.join(),
        travelClass,
      };

      destinationCodes.forEach((destinationCode) => {
        params[destinationCode] =
          propertyIdsByDestination[destinationCode].join();
      });

      return instance
        .get('/packages/cheapest-offer', { params })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - getPackagesCheapestOffer failed!');
        });
    };
  }

  static getPackageOffers(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      propertyId,
      departureDate,
      returnDate,
      adults,
      children,
      infants,
      originCode,
      destinationCode,
      travelClass,
    }) => {
      const params = {
        propertyId,
        departureDate,
        returnDate,
        adults,
        children,
        infants,
        originCode,
        destinationCode,
        travelClass,
      };

      return instance.get('/package/offers', { params }).then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getPackageOffers failed!');
      });
    };
  }

  static createBooking(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      pointsPay,
      qffPointTiers,
      payment,
      passengers,
      stay,
      airJourney,
      charges,
      deviceFingerprint,
      // baggage,
    }) =>
      instance
        .post('/bookings', {
          pointsPay,
          qffPointTiers,
          payment,
          passengers,
          stay,
          airJourney,
          charges,
          deviceFingerprint,
          // baggage,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - createBooking failed!');
        });
  }

  static contactUs(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      bookingReference,
      emailAddress,
      givenNames,
      lastName,
      message,
      phone,
      qantasFrequentFlyerNumber,
      title,
    }) =>
      instance
        .post('/cases', {
          bookingReference,
          emailAddress,
          givenNames,
          lastName,
          message,
          phone,
          qantasFrequentFlyerNumber,
          title,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - contactUs failed!');
        });
  }

  static searchAvailability(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ propertyIds, checkIn, checkOut, adults, children, infants }) =>
      instance
        .get('/availability', {
          params: {
            propertyIds: propertyIds.join(','),
            checkIn: dateParam(checkIn),
            checkOut: dateParam(checkOut),
            adults: numberParam(adults),
            children: numberParam(children),
            infants: numberParam(infants),
          },
        })
        .then((res) => res.data);
  }

  static createQuote(ctx) {
    const instance = getAPIRequest(ctx);

    return ({
      propertyId,
      roomTypeId,
      offerId,
      checkIn,
      checkOut,
      adults,
      children,
      infants,
      flights,
      ancillaries,
      destinationName,
    }) =>
      instance
        .post('/quote', {
          propertyId,
          roomTypeId,
          offerId,
          checkIn: dateParam(checkIn),
          checkOut: dateParam(checkOut),
          adults: numberParam(adults),
          children: numberParam(children),
          infants: numberParam(infants),
          flights,
          ancillaries,
          destinationName,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - createQuote failed!');
        });
  }

  static getAncillary(ctx) {
    const instance = getAPIRequest(ctx);

    return (query) => {
      const {
        propertyId,
        roomTypeId,
        offerId,
        adults,
        children,
        infants,
        departureDate,
        returnDate,
      } = query;

      const flightKeys = Object.keys(query).filter((key) =>
        key.includes('flight_'),
      );

      const flights = flightKeys.map((key) => {
        const params = query[key].split(',');

        const [
          direction,
          flightNumber,
          flightOriginCode,
          flightDestinationCode,
          flightDepartureDate,
          flightDepartureTime,
        ] = params;

        const [code, number] = flightNumber.split(/(\d+)/g);

        return {
          sequence_number: +key.split('_')[1],
          departure_date: flightDepartureDate,
          departure_time: flightDepartureTime,
          destination_code: flightDestinationCode,
          travel_class: 'economy',
          origin_code: flightOriginCode,
          flight_number: number,
          direction,
          carrier_code: code,
        };
      });

      return instance
        .post('/ancillary', {
          propertyId,
          roomTypeId,
          offerId,
          checkIn: dateParam(departureDate),
          checkOut: dateParam(returnDate),
          adults: numberParam(adults),
          children: numberParam(children),
          infants: numberParam(infants),
          flights,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - getAncillary failed!');
        });
    };
  }

  static getBooking(ctx) {
    const instance = getAPIRequest(ctx);

    return (bookingId) =>
      instance.get(`/bookings/${bookingId}`).then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getBooking failed!');
      });
  }

  static getDestination(ctx) {
    const instance = getAPIRequest(ctx);

    return (destinationName) =>
      instance.get(`/destinations/${destinationName}`).then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getDestination failed!');
      });
  }

  static getDestinations(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance.get('/destinations').then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getDestinations failed!');
      });
  }

  static getFeaturedProperties(ctx) {
    const instance = getAPIRequest(ctx);

    return () => instance.get('/featured-properties').then((res) => res.data);
  }

  static checkVoucher(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ propertyId, amount, code }) =>
      instance
        .post('/voucher/check', {
          code,
          propertyId,
          amount,
        })
        .then((res) => {
          if (res.data) return res.data;
          else throw new Error('BFF - checkVoucher failed!');
        });
  }

  static getVoucherValidation(ctx) {
    const instance = getAPIRequest(ctx);

    return (code) =>
      instance.get(`/voucher/validate/${code}`).then((res) => res.data);
  }

  static getVoucherBalance(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ code, pin }) =>
      instance.get(`/voucher/balance/${code}/${pin}`).then((res) => res.data);
  }

  static createCallback(ctx) {
    const instance = getAPIRequest(ctx);

    return ({ flyingFrom, name, phoneNumber, qff, scheduledTime }) =>
      instance
        .post('/callbacks', {
          flyingFrom,
          name,
          phoneNumber,
          qff,
          scheduledTime,
        })
        .then((res) => res.data);
  }

  static getLuxeCampaign(ctx) {
    const instance = getAPIRequest(ctx);

    return (slug) =>
      instance.get(`/luxe/campaigns/${slug}`).then((res) => res.data);
  }

  static getLuxeBlockoutDates(ctx) {
    const instance = getAPIRequest(ctx);

    return (slug, { adults, children, infants }) =>
      instance
        .get(`/luxe/blockout-dates/${slug}`, {
          params: {
            adults,
            children,
            infants,
          },
        })
        .then((res) => res.data);
  }

  static getLuxeCampaignOffers(ctx) {
    const instance = getAPIRequest(ctx);

    return (
      slug,
      {
        departureDate,
        returnDate,
        adults,
        children,
        infants,
        originCode,
        destinationCode,
        travelClass,
      },
    ) => {
      const params = {
        departureDate,
        returnDate,
        adults,
        children,
        infants,
        originCode,
        destinationCode,
        travelClass,
      };

      return instance
        .get(`/luxe/campaigns/${slug}/offers`, { params })
        .then((res) => res.data);
    };
  }

  static getCampaign(ctx) {
    const instance = getAPIRequest(ctx);

    return (campaignSlug) =>
      instance.get(`/campaigns/${campaignSlug}`).then((res) => res.data);
  }

  static getCampaignByDestination(ctx) {
    const instance = getAPIRequest(ctx);

    return (destinationName) =>
      instance
        .get(`/campaigns/destinations/${destinationName}`)
        .then((res) => res.data)
        .catch(() => undefined);
  }

  static getCampaignForPackage(ctx) {
    const instance = getAPIRequest(ctx);

    // for now, it just do same call as getCampaignByDestination
    // but if there is a property-based campaign, then need a new api to return it
    return (propertyId, destinationName) =>
      instance
        .get(`/campaigns/destinations/${destinationName}`)
        .then((res) => res.data)
        .catch(() => undefined);
  }

  static getCampaigns(ctx) {
    const instance = getAPIRequest(ctx);

    return () => instance.get('/campaigns').then((res) => res.data);
  }

  static getCampaignPills(ctx) {
    const instance = getAPIRequest(ctx);

    return () => instance.get('/campaigns/pills').then((res) => res.data);
  }

  static getAppSettings(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance.get('/settings/app').then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getAppSettings failed!');
      });
  }

  static getHomePage(ctx) {
    const instance = getAPIRequest(ctx);

    return () =>
      instance.get('/home-page').then((res) => {
        if (res.data) return res.data;
        else throw new Error('BFF - getHomePage failed!');
      });
  }

  static getProcuredOffer(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyId) =>
      instance
        .get(`/properties/${propertyId}/procured-offer`)
        .then((res) => res.data);
  }

  static getCalendarAvailabilityForProperty(ctx) {
    const instance = getAPIRequest(ctx);

    return (propertyId, startDate, endDate, luxeOnly = false) =>
      instance
        .get(`/calendar/property`, {
          params: {
            propertyId,
            startDate,
            endDate,
            luxeOnly,
          },
        })
        .then((res) => res.data);
  }

  static getCalendarAvailabilityForRoomType(ctx) {
    const instance = getAPIRequest(ctx);

    return (roomTypeId, startDate, endDate, luxeOnly = false) =>
      instance
        .get(`/calendar/room-type`, {
          params: {
            roomTypeId,
            startDate,
            endDate,
            luxeOnly,
          },
        })
        .then((res) => res.data);
  }
}

export default BFF;
