import format from './format';

// Expect 23:00 format not 2300
export const getMilitaryTime = (input) => {
  const hour = input.substring(0, 2);
  const minute = input.substring(3);

  return {
    hour: Number(hour),
    minute: Number(minute),
  };
};

const militaryTime = (input, dateFormat = 'h:mma') => {
  const { hour, minute } = getMilitaryTime(input);
  const date = new Date(0, 0, 0, hour, minute);

  return format(dateFormat)(date);
};

export default militaryTime;
