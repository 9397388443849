import dateFnsFormat from 'date-fns/format';
import { is } from 'ramda';

export const LONG_DATE_FORMAT = 'EEE, d MMM yyyy';
export const MEDIUM_DATE_FORMAT = 'd MMM yyyy';
export const ISO_DATE_ONLY_FORMAT = 'yyyy-MM-dd';

const getDate = (input) => {
  if (is(Date)(input)) {
    return input;
  }

  const result = /^(\d{4})-([01]\d)-([0-3]\d)$/.exec(input);
  if (result) {
    return new Date(
      parseFloat(result[1]),
      parseFloat(result[2] - 1),
      parseFloat(result[3]),
    );
  }

  return new Date(input);
};

const format = (dateFormat) => (input) => {
  const date = getDate(input);

  const formattedDate = dateFnsFormat(date, dateFormat);

  return is(String)(formattedDate) ? formattedDate : null;
};

export default format;

export const longDate = format(LONG_DATE_FORMAT);
export const ISODateOnly = format(ISO_DATE_ONLY_FORMAT);
